import { Box, Button, MenuItem, TextField, useTheme } from "@mui/material";
import { queryGetDashboardData } from "api/pixLimitValidator/pixLimitValidator";
import { useQueryGetUserInfo } from "api/security-api";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { currencyFormatter } from "helpers/currencyFormatter";
import { useCustomQuery } from "helpers/useCustomQuery";
import React, { useCallback, useEffect, useState } from "react";
import MetricsPanel from "./components/MetricsPanel";
import MetricsPanelSkeleton from "./components/MetricsPanelSkeleton";
import TedTransferDialog from "./components/TransferDialog";
import BaasRow from "components/atoms/Grid/Row";
import getSafe from "helpers/getSafe";
import { checkPermission } from "services/interfaceController";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import { queryGetProprietaryTefAccounts } from "api/pixLimitValidator/pixLimitValidator";
import AccountCard from "./components/AccountCard";
import { useInterval } from "helpers/useInterval";

//5 minutes
const DASH_INTERVAL_FETCH = 300000;
const ACCOUNTS_INTERVAL_FETCH = 20000;

const PixLimitValidatorDashboard = (props) => {
  const theme = useTheme();
  const { data: userInfo } = useQueryGetUserInfo();
  const { data: userData } = useQueryGetUserInfo();
  const [transferOpen, setTransferOpen] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [intervalValues, setIntervalValues] = useState({
    period: "60",
    interval: "1",
  });

  const [refetchAccounts, setRefetchAccounts] = useState(false);

  // const handleRefetch = useCallback(() => {
  //   setRefetch(!refetch);
  // }, [refetch]);

  const handleRefetchAccounts = useCallback(() => {
    setRefetchAccounts(!refetchAccounts);
  }, [refetchAccounts]);

  const {
    fetch: loadDashbordData,
    loading: loadingDashboard,
    data: dashboardData,
  } = useCustomQuery(queryGetDashboardData);

  const {
    fetch: loadAccountsData,
    loading: loadingAccounts,
    data: accountsData,
  } = useCustomQuery(queryGetProprietaryTefAccounts);

  useEffect(() => {
    loadAccountsData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchAccounts]);

  useEffect(() => {
    if (userInfo?.clientId) {
      loadDashbordData({
        values: {
          clientId: userInfo.clientId,
          timeInterval: intervalValues.interval,
          periodMinutes: intervalValues.period,
        },
        // onCompleted: () => setDashData(mockedDashData),
        emptyWarning: true,
        objectPath: "body",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.clientId]);

  useInterval(() => {
    setRefreshing(true);
    loadDashbordData({
      values: {
        clientId: userInfo.clientId,
        timeInterval: intervalValues.interval,
        periodMinutes: intervalValues.period,
      },
      emptyWarning: true,
      objectPath: "body",
      onCompleted: () => {
        // setDashData(mockedDashData);
        setRefreshing(false);
      },
    });
  }, DASH_INTERVAL_FETCH);

  useInterval(() => {
    loadAccountsData({});
  }, ACCOUNTS_INTERVAL_FETCH);

  return (
    <BaasFlex flexDirection="column" width="100%">
      <BaasPageHeader>Pix Limit Validator</BaasPageHeader>
      <BaasRow container spacing={1}>
        <BaasText sx={{ paddingLeft: "24px" }} variant="h6">
          Hello, {getSafe(userData, "fullName")}
        </BaasText>
      </BaasRow>
      {loadingAccounts && !accountsData.length ? (
        <Box
          display="flex"
          flexDirection="row"
          paddingLeft="16px"
          marginBottom="8px"
        >
          <BaasSkeleton
            variant="rectangular"
            width="242px"
            height="152px"
            sx={{ borderRadius: "8px", marginRight: "8px" }}
          />
          <BaasSkeleton
            variant="rectangular"
            width="242px"
            height="152px"
            sx={{ borderRadius: "8px" }}
          />
        </Box>
      ) : (
        <>
          {accountsData.length > 0 ? (
            <>
              {checkPermission([
                "balance-adm",
                "account-r",
                "all-w",
                "all-r",
              ]) &&
                checkPermission(["no-balance-v"], true) && (
                  <>
                    <BaasFlex
                      style={{
                        overflow: "auto",
                        paddingLeft: "12px",
                        maxWidth: "75vw",
                        paddingBottom: "8px",
                      }}
                    >
                      {(accountsData || []).map((account, index) => (
                        <AccountCard
                          key={`${account}_${index}`}
                          account={account}
                          index={index}
                        />
                      ))}
                    </BaasFlex>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      pl="16px"
                      mb={2}
                    >
                      <BaasText
                        sx={{ marginBottom: "16px", marginTop: "16px" }}
                        color="textSecondary"
                      >
                        Transfer between accounts
                      </BaasText>
                      <Box display="flex">
                        <Button
                          variant="contained"
                          onClick={() => setTransferOpen(true)}
                          sx={{ width: "120px" }}
                        >
                          Transfer
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
            </>
          ) : (
            <BaasText
              sx={{ marginBottom: "16px", paddingLeft: "16px" }}
              color="textSecondary"
            >
              No accounts found...
            </BaasText>
          )}
        </>
      )}
      <BaasContentGrid spacing={1}>
        <Box
          p={2}
          pb={3}
          sx={{ background: theme.palette.grey[100] }}
          display="flex"
          alignItems="center"
        >
          <BaasGrid spacing={1}>
            <BaasGrid>
              <BaasText sx={{ marginBottom: "24px" }} color="textSecondary">
                Bellow you can find the dashboards for your client's Pix Limit
                Validator operation within BTG Pactual Banking as a Service. The
                data displayed is real-time based on the time frame selected.
              </BaasText>
            </BaasGrid>
            <BaasGrid>
              <TextField
                size="small"
                label="Period"
                sx={{ minWidth: "180px" }}
                variant="standard"
                select
                SelectProps={{
                  value: intervalValues.period,
                  onChange: (e) => {
                    setIntervalValues({
                      period: e.target.value,
                      interval: 1,
                    });
                  },
                }}
              >
                <MenuItem value="60">Last 60 minutes</MenuItem>
                <MenuItem value="30">Last 30 minutes</MenuItem>
                <MenuItem value="15">Last 15 minutes</MenuItem>
              </TextField>
            </BaasGrid>
          </BaasGrid>
        </Box>
        <BaasGrid p="16px 0px" mb={2} item>
          <BaasGrid display="flex">
            <BaasText variant="h6">Client Operation Overview</BaasText>
          </BaasGrid>
        </BaasGrid>
        {!dashboardData || (!refreshing && loadingDashboard) ? (
          <>
            <MetricsPanelSkeleton />
            <MetricsPanelSkeleton />
            <MetricsPanelSkeleton />
          </>
        ) : (
          <>
            <MetricsPanel
              loading={loadingDashboard}
              chartData={{
                title: "CashOut Transactions Consolidated",
                items:
                  dashboardData?.cashOutAmountProcessed?.items.length > 0
                    ? dashboardData?.cashOutAmountProcessed?.items
                    : [],
                color: theme.palette.primary.main,
                // instruction: "chart instruction here",
              }}
              rightCard={{
                title: "CashOut Amount",
                subtitle: "Processed Total Value",
                value:
                  `R$ ${currencyFormatter(
                    Number(dashboardData?.cashOutAmountProcessed?.total)
                  )} ` || "N/A",
                instruction:
                  "Total amount of Pix CashOuts with confirmed status in the selected time-frame.",
              }}
              leftCard={{
                title: "Active Clients",
                value: dashboardData?.quantityActiveClients || "N/A",
                instruction:
                  "Number of different TaxIds that made transactions in the selected time-frame.",
              }}
            />
            <MetricsPanel
              chartData={{
                title: "CashIn Amount Processed",
                items:
                  dashboardData?.cashInAmountProcessed?.items.length > 0
                    ? dashboardData?.cashInAmountProcessed?.items
                    : [],
                color: theme.palette.primary.main,
                // instruction: "Chart instruction here",
              }}
              rightCard={{
                title: "CashIn Amount",
                subtitle: "Processed Total Value",
                value:
                  `R$ ${currencyFormatter(
                    Number(dashboardData?.cashInAmountProcessed?.total)
                  )} ` || "N/A",
                instruction:
                  "Total amount of Pix CashIns with confirmed status in the selected time-frame.",
              }}
            />
            <BaasGrid p="16px 0px" mb={2} item>
              <BaasGrid display="flex">
                <BaasText variant="h6">QR Codes</BaasText>
              </BaasGrid>
            </BaasGrid>
            <MetricsPanel
              chartData={{
                title: "QR Codes Created",
                items:
                  dashboardData?.qrCode?.itemsCreated.length > 0
                    ? dashboardData?.qrCode?.itemsCreated
                    : [],
                color: theme.palette.primary.main,
                // instruction: "Chart instruction here",
              }}
              rightCard={{
                title: "Total QR Codes Created",
                value: dashboardData?.qrCode?.totalCreated || "N/A",
                instruction: "QR created instruction here",
              }}
              leftCard={{
                title: "QR Code Conversion Rate",
                value: dashboardData?.qrCode?.conversionRate
                  ? `${dashboardData?.qrCode?.conversionRate}%`
                  : "N/A",
                instruction: "QR Conversion instruction here",
              }}
            />
            <MetricsPanel
              chartData={{
                title: "QR Codes Paid",
                items:
                  dashboardData?.qrCode?.itemsPaid.length > 0
                    ? dashboardData?.qrCode?.itemsPaid
                    : [],
                color: theme.palette.primary.main,
                // instruction: "Chart instruction here",
              }}
              rightCard={{
                title: "Total QR Codes Paid",
                value: dashboardData?.qrCode?.totalPaid || "N/A",
                instruction: "Total QR instruction here",
              }}
            />
          </>
        )}
      </BaasContentGrid>
      {transferOpen && (
        <TedTransferDialog
          open={transferOpen}
          accounts={accountsData}
          setOpen={setTransferOpen}
          handleRefetchAccounts={handleRefetchAccounts}
        />
      )}
    </BaasFlex>
  );
};
export default PixLimitValidatorDashboard;
