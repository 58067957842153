import BaasFlex from "components/atoms/Flex/Flex";
import BaasAppBar from "components/templates/AppBar/AppBar";
import BaasHeader from "components/templates/Header/Header";
import { routes } from "constants/routes";
import { useAuth } from "providers/auth";
import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Endpoint from "constants/endpoints";
import { checkPermission } from "services/interfaceController";

const publicRouterOnlyProd = [
  "/dochub",
  "/docv1",
  "/doccm",
  "/docob",
  "/docpc",
  "/docpi",
  "/docpm",
  "/doctr",
  "/faq",
  "/landingpage",
  "/docld",
  "/docda",
];

const publicRouterOnlySandbox = ["/onboarding"];

const BaaSRoute = ({ component: Component, roles, isPrivate, ...rest }) => {
  const { authenticated } = useAuth();

  const history = useHistory();

  if (isPrivate && !authenticated) {
    return <Redirect to={{ pathname: "/login" }} />;
  } else if (isPrivate && authenticated && !checkPermission(roles)) {
    return <Redirect to={{ pathname: "/dashboard" }} />;
  } else if (
    !isPrivate &&
    Endpoint.Environment === "Sandbox" &&
    !!publicRouterOnlyProd.find((route) => route === rest.path)
  ) {
    window.location.href = "https://developer.btgpactual.com/landingpage";
  } else if (
    !isPrivate &&
    Endpoint.Environment !== "Sandbox" &&
    !!publicRouterOnlySandbox.find((route) => route === rest.path)
  ) {
    history.push("/landingpage");
  }
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {!isPrivate && <BaasHeader />}
          <BaasFlex>
            {isPrivate && <BaasAppBar />}
            <Component {...props} />
          </BaasFlex>
        </>
      )}
    />
  );
};

const Pages = () => {
  return (
    <>
      <Switch>
        {routes.map((page) => {
          return page.subRoute ? (
            page.subRoute.map((subPage) => (
              <BaaSRoute
                exact
                path={subPage.path}
                component={subPage.component}
                roles={subPage.permission}
                isPrivate={subPage.privateRoute}
              />
            ))
          ) : (
            <BaaSRoute
              exact
              path={page.path}
              component={page.component}
              roles={page.permission}
              isPrivate={page.privateRoute}
            />
          );
        })}
        <Redirect to="/landingpage" />
      </Switch>
    </>
  );
};

export default Pages;
